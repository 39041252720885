<template>
  <b-img
    style="height: 3em"
    :src="appLogoImage"
    alt="logo"
  />
</template>

<script>
import { $themeConfig } from '@themeConfig'
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  computed: {
    appLogoImage() {
      return $themeConfig.app.appLogoImage
    },
  },
}
</script>
